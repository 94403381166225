<template>
	<div id="wrap" class="loginWrap v2">
		<!-- titArea -->
		<div class="titArea">
			<div class="inner">
				<h2>회원가입</h2>
				<a class="btnBack" @click="login()">이전 페이지</a>
			</div>
		</div>
		<!-- container -->
		<div id="container">
			<!-- photoWrap -->
			<div class="photoWrap">
				<!-- photoArea -->
				<div class="photoArea">
					<div class="photo">
						<img :src="imagePreview" alt="" />
					</div>
					<a class="btnDel" @click="onImageDelete" v-if="files.length > 0">이미지 삭제</a>
					<span class="btnPhoto">
						<input type="file" title="파일 업로드" id="file" accept="image/*" @change="onImageUpload" ref="userImage" />
					</span>
				</div>
				<!--// photoArea -->
			</div>
			<!-- photoWrap -->
			<!-- contents -->
			<div class="contents">
				<!-- contCenter -->
				<div class="contCenter">
					<!-- inputList -->
					<div class="inputList">
						<dl>
							<dt>닉네임 <em>*</em></dt>
							<dd class="flex">
								<input
									type="text"
									title="닉네임 입력"
									placeholder="닉네임을 입력해주세요."
									v-model="nickname"
									maxlength="20"
								/>
								<button type="button" class="btn" @click="onNicknameDuplicate()">중복확인</button>
							</dd>
						</dl>
						<dl>
							<dt>아이디 <em>*</em></dt>
							<dd class="flex">
								<input
									type="text"
									title="아이디 입력"
									placeholder="아이디(이메일주소)를 입력해주세요."
									v-model="loginId"
									maxlength="50"
								/>
								<button type="button" class="btn" @click="onLoginIdDuplicate()">중복확인</button>
							</dd>
						</dl>
						<dl>
							<dt>비밀번호 <em>*</em></dt>
							<dd>
								<input
									type="password"
									title="비밀번호 입력"
									placeholder="비밀번호(영문,숫자,특수문자 8~20자)"
									v-model="password"
									maxlength="20"
								/>
							</dd>
						</dl>
						<dl>
							<dt>비밀번호 확인 <em>*</em></dt>
							<dd>
								<input
									type="password"
									title="비밀번호 재입력"
									placeholder="비밀번호 확인(비밀번호를 한번 더 입력해주세요.)"
									v-model="password2"
									maxlength="20"
								/>
							</dd>
						</dl>
					</div>
					<!--// inputList -->
					<!-- agreeWrap -->
					<div class="agreeWrap">
						<span class="checkbox">
							<input type="checkbox" id="chkAll" v-model="chkAll" @click="onTerms()" />
							<label for="chkAll">전체 약관 동의 <em>*</em></label>
						</span>
						<dl>
							<dt>
								<span class="checkbox">
									<input type="checkbox" id="chk" v-model="termIds" :value="term1.id" />
									<label for="chk01">서비스 이용약관 동의</label>
								</span>
							</dt>
							<dd>
								<pre v-if="term1.content" style="font-family: 'Noto Sans KR', sans-serif;">{{
									term1.content.replace(/ /g, '&nbsp;')
								}}</pre>
							</dd>
						</dl>
						<dl>
							<dt>
								<span class="checkbox">
									<input type="checkbox" id="chk" v-model="termIds" :value="term2.id" />
									<label for="chk02">개인정보보호 약관 동의</label>
								</span>
							</dt>
							<dd>
								<pre v-if="term2.content" style="font-family: 'Noto Sans KR', sans-serif;">{{
									term2.content.replace(/ /g, '&nbsp;')
								}}</pre>
							</dd>
						</dl>
					</div>
					<!--// agreeWrap -->
					<!-- btnArea -->
					<div class="btnArea">
						<button type="button" class="btn" @click="onRegister()" :disabled="!registBtn">회원가입</button>
					</div>
					<div id="spinBox"></div>
					<!--// btnArea -->
				</div>
				<!--// contCenter -->
			</div>
			<!--// contents -->
		</div>
		<!--// container -->
	</div>
</template>

<script>
import terms from '@/api/terms';
import users from '@/api/user';
import { showSpinner, hideSpinner, isImage, overFileSize } from '@/utils/cmm';
export default {
	mounted() {
		this.getTerms();
	},
	watch: {
		nickname: function() {
			this.duplicateNickname = false;
		},
		loginId: function() {
			this.duplicateLoginId = false;
		},
	},
	computed: {
		chkAll: {
			get() {
				let check = false;
				this.termIds.length == this.terms.total ? (check = true) : (check = false);
				return check;
			},
			set() {},
		},
		registBtn: {
			get() {
				return (
					this.password.length > 0 && this.password2.length > 0 && this.termIds[0] != null && this.termIds[1] != null
				);
			},
			set() {},
		},
	},
	data() {
		return {
			files: [],
			loginId: '',
			password: '',
			password2: '',
			nickname: '',
			terms: [],
			term1: '',
			term2: '',
			termIds: [],
			termsTotal: 0,
			duplicateNickname: false,
			duplicateLoginId: false,
			imagePreview: '/resources/images/no-img.svg',
			defaultPreview: '/resources/images/no-img.svg',
		};
	},
	methods: {
		login() {
			this.$router.push({ name: 'login' });
		},
		async getTerms() {
			this.terms = await terms.get();
			this.term1 = this.terms.data[0];
			this.term2 = this.terms.data[1];
			this.termsTotal = this.terms.total;
		},
		onImageUpload(e) {
			if (e.target.files[0] != undefined) {
				this.files = [];
				if (!isImage(e.target.files[0])) {
					this.$alert(`이미지는 png, jpg, jpeg, bmp의 확장자 파일만 올려주세요.`);
					return false;
				}
				if (overFileSize([], e.target.files[0], 0)) {
					document.querySelector('#file').value = '';
					return this.$alert(`이미지 파일의 용량은 100MB를 초과할 수 없습니다.`);
				}
				this.files.push(e.target.files[0]);
				this.imagePreview = URL.createObjectURL(this.files[0]);
			}
			document.querySelector('#file').value = '';
		},
		onImageDelete() {
			this.files = [];
			this.imagePreview = this.defaultPreview;
			document.querySelector('#file').value = '';
		},
		onTerms() {
			if (this.termIds.length == 2) {
				this.termIds = [];
			} else {
				this.termIds = [];
				this.termIds.push(this.term1.id);
				this.termIds.push(this.term2.id);
			}
		},
		async onNicknameDuplicate() {
			try {
				showSpinner();
				await users.duplicateNickname(this.nickname);
			} catch (ex) {
				return await this.$alert(`${ex.message}`, '회원가입 안내');
			} finally {
				hideSpinner();
			}
			this.duplicateNickname = true;
			this.$alert(`사용할 수 있는 닉네임입니다.`, '회원가입 안내');
		},
		async onLoginIdDuplicate() {
			try {
				showSpinner();
				await users.duplicateLoginId(this.loginId);
			} catch (ex) {
				return await this.$alert(`${ex.message}`, '회원가입 안내');
			} finally {
				hideSpinner();
			}
			this.duplicateLoginId = true;
			this.$alert(`사용할 수 있는 아이디입니다.`, '회원가입 안내');
		},
		async onRegister() {
			if (!this.duplicateNickname) {
				this.$alert(`닉네임 중복체크를 해주세요.`, '회원가입 안내');
				return false;
			}
			if (!this.duplicateLoginId) {
				this.$alert(`아이디 중복체크를 해주세요.`, '회원가입 안내');
				return false;
			}
			try {
				showSpinner();
				const formData = new FormData();
				formData.append('nickname', this.nickname);
				formData.append('loginId', this.loginId);
				formData.append('password', this.password);
				formData.append('password2', this.password2);
				formData.append('termIds', this.termIds);
				if (this.files.length > 0) {
					for (let index in this.files) {
						formData.append('file', this.files[index]);
					}
				}
				await users.save(formData);
			} catch (ex) {
				return await this.$alert(`${ex.message}`, '회원가입 안내');
			} finally {
				hideSpinner();
			}

			this.$router.push({ name: 'login' });
		},
	},
};
</script>

<style scoped></style>
